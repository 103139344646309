import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import IslamicBooks from './components/IslamicBooks';
import Gallery from './components/Gallery';
import AboutHistory from './components/about/History';
import AboutVisionMission from './components/about/VisionMission';
import AboutNews from './components/about/News';
import AboutLatestUpdate from './components/about/LatestUpdate';
import PeopleDoner from './components/people/Doner';
import PeopleManagingCommittee from './components/people/ManagingCommittee';
import PeopleAdvisoryCommittee from './components/people/AdvisoryCommittee';
import PeopleWorkingCommittee from './components/people/WorkingCommittee';
import PlanningFuturePlanning from './components/plannings/FuturePlanning';
import PlanningCurrentProgress from './components/plannings/CurrentProgress';
import PlanningFundsExpenses from './components/plannings/FundsExpenses';
import Donation from './components/Donation';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about">
            <Route path="history" element={<AboutHistory />} />
            <Route path="vision-mission" element={<AboutVisionMission />} />
            <Route path="news" element={<AboutNews />} />
            <Route path="latest-update" element={<AboutLatestUpdate />} />
          </Route>
          <Route path="people">
            <Route path="doner" element={<PeopleDoner />} />
            <Route path="managing-committee" element={<PeopleManagingCommittee />} />
            <Route path="advisory-committee" element={<PeopleAdvisoryCommittee />} />
            <Route path="working-committee" element={<PeopleWorkingCommittee />} />
          </Route>
          <Route path="plannings">
            <Route path="future-planning" element={<PlanningFuturePlanning />} />
            <Route path="current-progress" element={<PlanningCurrentProgress />} />
            <Route path="funds-expenses" element={<PlanningFundsExpenses />} />
          </Route>
          <Route path="donation" element={<Donation />} />
          <Route path="contact" element={<Contact />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="islamic-books" element={<IslamicBooks />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
