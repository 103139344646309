import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import Footer from './Footer';
import LanguageSelector from './LanguageSelector';
import useTranslationHook from '../useTranslationHook';

const Layout = () => {
  const { t } = useTranslationHook();

  return (
    <>
      <nav
        className='navbar navbar-expand-lg navbar-dark fixed-top'
        id='mainNav'
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          backgroundColor: '#1B1A55',
          zIndex: 1030,
        }}
      >
        <div className='container-fluid'>
          <Link className='navbar-brand' to='/'>
            <img
              src={require('../assets/images/logo.png')}
              width='40'
              height='50'
              alt='Logo'
            />
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNavDropdown'
            aria-controls='navbarNavDropdown'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            {t('menu.menu')}
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarNavDropdown'>
            <ul className='navbar-nav text-uppercase ms-auto py-4 py-lg-0'>
              <li className='nav-item'>
                <Link className='nav-link' to='/'>
                  <i className='fa fa-home' aria-hidden='true'></i>
                  <span>{t('menu.home')}</span>
                </Link>
              </li>
              <li className='nav-item dropdown'>
                <Link
                  className='nav-link dropdown-toggle'
                  to='#'
                  id='navbarDropdownMenuLink'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <i className='fa fa-info-circle' aria-hidden='true'></i>
                  {t('menu.about')}
                </Link>
                <ul className='dropdown-menu' aria-labelledby='navbarDropdownMenuLink'>
                  <li>
                    <Link className='dropdown-item' to='/about/history'>
                      {t('menu.aboutSub.history')}
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/about/vision-mission'>
                      {t('menu.aboutSub.visionMission')}
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/about/news'>
                      {t('menu.news')}
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/about/latest-update'>
                      {t('menu.aboutSub.latest_update')}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className='nav-item dropdown'>
                <Link
                  className='nav-link dropdown-toggle'
                  to='#'
                  id='navbarDropdownPeopleLink'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <i className='fa fa-users' aria-hidden='true'></i>
                  <span>{t('menu.people')}</span>
                </Link>
                <ul className='dropdown-menu' aria-labelledby='navbarDropdownPeopleLink'>
                  <li>
                    <Link className='dropdown-item' to='/people/doner'>
                      {t('menu.peopleSub.doner')}
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/people/managing-committee'>
                      {t('menu.peopleSub.managingCommittee')}
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/people/advisory-committee'>
                      {t('menu.peopleSub.advisoryCommittee')}
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/people/working-committee'>
                      {t('menu.peopleSub.workingCommittee')}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className='nav-item dropdown'>
                <Link
                  className='nav-link dropdown-toggle'
                  to='#'
                  id='navbarDropdownPlanningLink'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <i className='fa fa-calendar' aria-hidden='true'></i>
                  <span>{t('menu.planning')}</span>
                </Link>
                <ul className='dropdown-menu' aria-labelledby='navbarDropdownPlanningLink'>
                  <li>
                    <Link className='dropdown-item' to='/plannings/future-planning'>
                      {t('menu.planningSub.FuturePlanning')}
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/plannings/current-progress'>
                      {t('menu.planningSub.CurrentProgress')}
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/plannings/funds-expenses'>
                      {t('menu.planningSub.FundsExpenses')}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/donation'>
                  <i className='fa fa-hand-holding-heart' aria-hidden='true'></i>
                  <span>{t('menu.donation')}</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/contact'>
                  <i className='fa fa-envelope' aria-hidden='true'></i>
                  <span>{t('menu.contact')}</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/gallery'>
                  <i className='fa fa-images' aria-hidden='true'></i>
                  <span>{t('menu.gallery')}</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/islamic-books'>
                  <span>{t('menu.islamicBooks')}</span>
                </Link>
              </li>
            </ul>
            <ul className='nav navbar-nav navbar-right'>
              <div className='d-flex align-items-center'>
                <LanguageSelector />
              </div>
            </ul>
          </div>
        </div>
      </nav>

      <main className='mt-6'>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
