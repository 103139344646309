import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/custom.css';
import useTranslationHook from '../useTranslationHook';
import { FaFacebook, FaTwitter, FaInstagram, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  const { t } = useTranslationHook();
  
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="container py-5">
          <div className="row g-4">
            {/* Quick Links */}
            <div className="col-lg-3 col-md-6">
              <h5 className="footer-heading">{t('menu.pages')}</h5>
              <ul className="footer-links">
                <li><Link to="/">{t('menu.home')}</Link></li>
                <li><Link to="/about">{t('menu.about')}</Link></li>
                <li><Link to="/people">{t('menu.people')}</Link></li>
                <li><Link to="/plannings">{t('menu.planning')}</Link></li>
              </ul>
            </div>

            {/* Resources */}
            <div className="col-lg-3 col-md-6">
              <h5 className="footer-heading">{t('menu.pages')}</h5>
              <ul className="footer-links">
                <li><Link to="/donation">{t('menu.donation')}</Link></li>
                <li><Link to="/contact">{t('menu.contact')}</Link></li>
                <li><Link to="/gallery">{t('menu.gallery')}</Link></li>
              </ul>
            </div>

            {/* Contact Information */}
            <div className="col-lg-3 col-md-6">
              <h5 className="footer-heading">{t('menu.contact')}</h5>
              <address className="footer-contact">
                <p>{t('address.line1')}</p>
                <p>{t('address.line2')}</p>
                <p className="mb-2">
                  <strong>Phone:</strong> +91-702-115-4715
                </p>
                <a href="mailto:duas.nikhra@gmail.com" className="footer-email">
                  duas.nikhra@gmail.com
                </a>
              </address>
            </div>

            {/* Social Media */}
            <div className="col-lg-3 col-md-6">
              <h5 className="footer-heading">{t('menu.followUs')}</h5>
              <div className="social-links">
                <a href="#" className="social-link" aria-label="Facebook">
                  <FaFacebook />
                </a>
                <a href="#" className="social-link" aria-label="Twitter">
                  <FaTwitter />
                </a>
                <a href="#" className="social-link" aria-label="Instagram">
                  <FaInstagram />
                </a>
                <a href="#" className="social-link" aria-label="WhatsApp">
                  <FaWhatsapp />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <p className="text-center mb-0">
            &copy; {new Date().getFullYear()} Madrasa Ashrafia Siddiquia Nikhra. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
