import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import useTranslationHook from '../useTranslationHook';
import FeedbackModal from './Modal';

const Contact = () => {
  const form = useRef();
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_nuxu5ac',
        'template_contact_form1',
        form.current,
        '5yQS5LA4_3Ect9Vv0'
      )
      .then(
        (result) => {
          setMessage('Your message has been sent successfully!');
          setShowModal(true);
          form.current.reset(); // Reset the form after submission
        },
        (error) => {
          setMessage('Failed to send the message, please try again.');
          setShowModal(true);
        }
      );
  };
  const { t } = useTranslationHook();
  return (
    <div className='container mt-5'>
      <div className='bg-light p-5 rounded shadow'>
        <h2 className='mb-4 text-center'>{t('menu.contact')}</h2>
        <div className='row mb-5'>
          <div className='col-lg-4 mb-3'>
            <ul className='contact-short-info mt-4'>
              <li className='mb-3'>
                <i className='tf-ion-ios-home'></i>
                <span>
                  <strong>{t('address.name')}</strong>
                  <br />
                </span>
                <br />
                <span className='ml-2'>
                  {t('address.line1')} <br />
                  {t('address.line2')}
                </span>
              </li>
              <li className='mb-3'>
                <i className='tf-ion-android-phone-portrait'></i>
                <span>Phone: +91-702-115-4715</span>
              </li>
              <li className='mb-3'>
                <i className='tf-ion-android-globe'></i>
                <span>Fax: +880-31-000-000</span>
              </li>
              <li>
                <i className='tf-ion-android-mail'></i>
                <span>Email: duas.nikhra@gmail.com</span>
              </li>
            </ul>
          </div>
          <div className='col-lg-8'>
            <form ref={form} onSubmit={sendEmail} className='row g-3'>
              <div className='col-md-6'>
                <label htmlFor='from_name' className='form-label'>Name</label>
                <input
                  type='text'
                  className='form-control'
                  name='from_name'
                  id='from_name'
                  placeholder='Enter your name'
                  required
                />
              </div>
              <div className='col-md-6'>
                <label htmlFor='from_email' className='form-label'>Email</label>
                <input
                  type='email'
                  className='form-control'
                  name='from_email'
                  id='from_email'
                  placeholder='Enter your email'
                  required
                />
              </div>
              <div className='col-12'>
                <label htmlFor='message' className='form-label'>Message</label>
                <textarea
                  className='form-control'
                  name='message'
                  id='message'
                  rows='4'
                  placeholder='Type your message here...'
                  required
                ></textarea>
              </div>
              <div className='col-12 text-center'>
                <button type='submit' className='btn btn-primary'>Send Message</button>
              </div>
            </form>
            <FeedbackModal show={showModal} handleClose={() => setShowModal(false)} message={message} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
