import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Import the worker from the public folder
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const PDFViewer = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  const onDocumentLoadError = (error) => {
    console.error('Error loading PDF:', error);
    setError('Failed to load PDF. Please try again later.');
    setLoading(false);
  };

  const previousPage = () => {
    setPageNumber(prevPage => Math.max(prevPage - 1, 1));
  };

  const nextPage = () => {
    setPageNumber(prevPage => Math.min(prevPage + 1, numPages || 1));
  };

  if (!pdfUrl) {
    return <div className="alert alert-warning">No PDF URL provided</div>;
  }

  return (
    <div className="pdf-viewer">
      {loading && (
        <div className="text-center my-4">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      <div className="text-center">
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          loading={
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          }
        >
          <Page 
            pageNumber={pageNumber} 
            renderTextLayer={false}
            renderAnnotationLayer={false}
            scale={1.2}
            loading={
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading page...</span>
              </div>
            }
          />
        </Document>

        {!loading && !error && numPages > 0 && (
          <div className="mt-3 mb-5">
            <div className="btn-group">
              <button
                className="btn btn-primary"
                onClick={previousPage}
                disabled={pageNumber <= 1}
              >
                Previous
              </button>
              <button
                className="btn btn-secondary"
                disabled
              >
                Page {pageNumber} of {numPages}
              </button>
              <button
                className="btn btn-primary"
                onClick={nextPage}
                disabled={pageNumber >= numPages}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PDFViewer;
