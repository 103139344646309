import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PDFViewer from './PDFViewer';
import booksData from '../data/books.json';
import useTranslationHook from '../useTranslationHook';
import './IslamicBooks.css';

const IslamicBooks = () => {
  const [books, setBooks] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('all');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedBook, setSelectedBook] = useState(null);
  const { t } = useTranslationHook();
  const navigate = useNavigate();

  useEffect(() => {
    setBooks(booksData.books || []);
  }, []);

  const handleBookSelect = (book) => {
    setSelectedBook(book);
  };

  const handleCloseViewer = () => {
    setSelectedBook(null);
  };

  const filteredBooks = books.filter(book => {
    const languageMatch = selectedLanguage === 'all' || book.language === selectedLanguage;
    const categoryMatch = selectedCategory === 'all' || book.category === selectedCategory;
    return languageMatch && categoryMatch;
  });

  const languages = ['all', ...new Set(books.map(book => book.language))];
  const categories = ['all', ...new Set(books.map(book => book.category))];

  if (selectedBook) {
    return (
      <div className="container my-4">
        <button
          className="btn btn-secondary mb-4"
          onClick={handleCloseViewer}
        >
          ← Back to Books
        </button>
        <h3 className="mb-4">{selectedBook.title}</h3>
        <PDFViewer pdfUrl={selectedBook.downloadUrl} />
      </div>
    );
  }

  return (
    <div className="container my-4">
      <h2 className="text-center mb-4">{t('menu.islamicBooks')}</h2>
      
      <div className="row mb-4">
        <div className="col-md-6 mb-3">
          <label className="form-label">Filter by Language:</label>
          <select
            className="form-select"
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
          >
            {languages.map(lang => (
              <option key={lang} value={lang}>
                {lang.charAt(0).toUpperCase() + lang.slice(1)}
              </option>
            ))}
          </select>
        </div>
        
        <div className="col-md-6 mb-3">
          <label className="form-label">Filter by Category:</label>
          <select
            className="form-select"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            {categories.map(cat => (
              <option key={cat} value={cat}>
                {cat.charAt(0).toUpperCase() + cat.slice(1)}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="row">
        {filteredBooks.map(book => (
          <div key={book.id} className="col-md-4 mb-4">
            <div className="card h-100">
              <img
                src={book.coverImage || '/book-covers/default-cover.jpg'}
                className="card-img-top"
                alt={book.title}
                style={{ height: '200px', objectFit: 'cover' }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/book-covers/default-cover.jpg';
                }}
              />
              <div className="card-body">
                <h5 className="card-title">{book.title}</h5>
                <p className="card-text">
                  <small className="text-muted">By {book.author}</small>
                </p>
                <p className="card-text">{book.description}</p>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="badge bg-primary">{book.language}</span>
                  <span className="badge bg-secondary">{book.category}</span>
                </div>
              </div>
              <div className="card-footer">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => handleBookSelect(book)}
                >
                  Read Book
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {filteredBooks.length === 0 && (
        <div className="alert alert-info text-center">
          No books found matching the selected filters.
        </div>
      )}
    </div>
  );
};

export default IslamicBooks;
